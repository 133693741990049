import React from 'react';
import styles from './styles.module.scss';

export default function Pulse({ size, color }) {
  return (
    <div
      className={styles.loader}
      style={{
        width: size,
        height: size,
        background: color,
      }}></div>
  );
}
