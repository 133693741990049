import React, { useReducer } from 'react';

import Input from '../../app/Input';
import AuraButton from '../../app/AuraButton';

import useToastMessage from '../../../hooks/toastMessage';
import useTranslations from '../../../hooks/translations';

import Auth from '../../../services/FirebaseAuth';

import { isValidEmail } from '../../../utils/validators';
import { getUserFromEmail } from '../../../models/user';

import styles from './styles.module.scss';

const initialState = {
  email: '',
  password: '',
  givenName: '',
};

function reducer(state, action) {
  switch (action.type) {
    case 'updateEmail':
      return { ...state, email: action.data };
    case 'updatePassword':
      return { ...state, password: action.data };
    case 'updateName':
      return { ...state, givenName: action.data };
    default:
      return state;
  }
}

export default function EmailLoginForm({
  isSignUp,
  onSubmit,
  loginOnExistingUserSignUp,
  btnStyle,
  btnText,
  showForgotPassword = true,
  showErrorToasts = true,
}) {
  const { t } = useTranslations();
  const [state, dispatch] = useReducer(reducer, initialState);
  const Toast = useToastMessage();
  const { email, password, givenName } = state;

  function onChangePassword(evt) {
    dispatch({ type: 'updatePassword', data: evt.target.value });
  }

  function onChangeEmail(evt) {
    dispatch({ type: 'updateEmail', data: evt.target.value?.trim() });
  }

  function onChangeName(evt) {
    dispatch({ type: 'updateName', data: evt.target.value });
  }

  function handleSubmit(evt) {
    evt.preventDefault();
    if (isSignUp && (!givenName || givenName.trim() === '')) {
      handleError('Please enter a valid name');
      return;
    }
    if (!isValidEmail(email)) {
      handleError('Please enter a valid email');
      return;
    }
    if (!password || password === '') {
      handleError('Please enter password');
      return;
    }
    if (isSignUp && password.length < 7) {
      handleError('Password must be 7+ characters');
      return;
    }
    if (onSubmit) {
      onSubmit({ email, password, givenName });
    }
    if (isSignUp) {
      Auth.createUserWithEmailAndPassword(
        Auth.instance,
        email.trim(),
        password.trim()
      ).catch(handleAuthError);
    } else {
      Auth.signInWithEmailAndPassword(
        Auth.instance,
        email.trim(),
        password.trim()
      ).catch(handleAuthError);
    }
  }

  async function handleForgotPassword() {
    if (!isValidEmail(email)) {
      handleError('Please enter a valid email first!');
      return;
    }
    const profile = await getUserFromEmail(email);
    if (profile) {
      if (profile.provider !== `password`) {
        handleError(
          'Account found with Google or Facebook login. Please try the relevant sign in option.'
        );
      } else {
        try {
          await Auth.sendPasswordResetEmail(Auth.instance, email);

          Toast.showSuccess(
            `Password reset email sent! It will arrive in a few minutes`
          );
        } catch (error) {
          handleError(error.message);
        }
      }
    } else {
      handleError('No user with entered email found');
    }
  }

  function handleAuthError(error) {
    const errorCode = error.code;
    switch (errorCode) {
      case 'auth/wrong-password':
        handleError('Invalid password!');
        break;
      case 'auth/invalid-email':
      case 'auth/user-not-found':
        handleError('No account found! Please sign up for Aura first!');
        break;
      case 'auth/user-disabled':
        handleError('Your account is disabled. Please contact Aura support');
        break;
      case 'auth/email-already-in-use':
        if (loginOnExistingUserSignUp) {
          Auth.signInWithEmailAndPassword(
            Auth.instance,
            email.trim(),
            password.trim()
          ).catch(handleAuthError);
        } else {
          handleError(
            'The email address is already in use by another account. If it belongs to you please log in'
          );
        }
        break;
      case 'auth/weak-password':
        handleError(
          'Your password is very weak. Please use a stronger password'
        );
        break;
      case 'auth/too-many-requests':
        handleError(
          'Blocked due to several failed login attempts. Please try again in some time.'
        );
        break;
      default:
        handleError(`Unknown error ${errorCode}`);
        break;
    }
  }

  function handleError(errorMessage) {
    if (showErrorToasts) {
      Toast.showError(errorMessage);
    }
  }

  return (
    <form className={styles.container} onSubmit={handleSubmit}>
      {isSignUp && (
        <Input
          className={styles.input}
          style={{ marginBottom: 12 }}
          type="text"
          onChange={onChangeName}
          value={givenName}
          placeholder={t('onboarding_sign_up_first_name')}
        />
      )}
      <Input
        className={styles.input}
        style={{ marginBottom: 12 }}
        type="text"
        onChange={onChangeEmail}
        value={email}
        placeholder={t('onboarding_login_placeholder_email')}
      />
      <Input
        className={styles.input}
        style={{ marginBottom: 12 }}
        type="password"
        onChange={onChangePassword}
        placeholder={t('onboarding_login_placeholder_password')}
      />
      <AuraButton
        type="submit"
        style={{ height: 40, width: '100%', ...btnStyle }}
        title={btnText || t('onboarding_sign_up_login')}
        onClick={handleSubmit}
      />
      {showForgotPassword && !isSignUp && (
        <span
          className={styles.forgotPasswordText}
          onClick={handleForgotPassword}>
          {t('onboarding_login_button_forgot_password')}
        </span>
      )}
    </form>
  );
}
