import React from 'react';
import Head from 'next/head';

const DefaultHeadTags = () => {
  return (
    <Head>
      <title key="title">Aura - Coach Web Platform</title>

      <meta
        key="description"
        name="description"
        content="Become an Aura Coach. Manage your channels, tracks and view insights on what content users love on Aura."
      />

      <meta
        key="og:title"
        property="og:title"
        content="Aura - Coach Web Platform"
      />

      <meta
        key="og:description"
        property="og:description"
        content="Become an Aura Coach. Manage your channels, tracks and view insights on what content users love on Aura."
      />

      <meta
        key="og:image"
        property="og:image"
        content={`${process.env.NEXT_PUBLIC_APP_DOMAIN}/static/images/banner.jpg`}
      />

      <meta
        key="twitter:card"
        name="twitter:card"
        content="summary_large_image"
      />

      <meta name="robots" content="noindex, nofollow" />

      <link
        rel="icon"
        type="image/png"
        href="/static/images/icons/auraLogo.png"
      />

      <link rel="manifest" href="/manifest.json" />

      <link
        rel="apple-touch-icon"
        type="image/png"
        href="/static/images/icons/auraLogo.png"
      />

      <link rel="stylesheet" href="/static/css/background-gradient.css" />
    </Head>
  );
};

export default DefaultHeadTags;
