const documentConstants = Object.freeze({
  COACH_INTRODUCTION:
    'https://docs.google.com/document/d/e/2PACX-1vTpvgLZFLhVPOLFeANoW5fPxd5UYah4AIXWCokClou3RzSsLEvwt38pq2pGEcx3X2c3Ad_12VzqaqbU/pub',
  COACH_GUIDELINES:
    'https://docs.google.com/document/d/e/2PACX-1vQWerZDz6vOITdMOGPahX5DqpuRPXElI2TzlcchbpgoZzrzJgXeicWo8rNMgzLdEmUVBYsrsasEXUye/pub',
  RECORDING_TUTORIAL:
    'https://docs.google.com/presentation/d/e/2PACX-1vQ0TTdrOLictvHieNkI7isrD0ivZDD4I-Ylvi2_DSh0Xl4zuh2lX8TOyUWTLd3_AE06JdpHtsmQecVO/pub?start=true&loop=false&delayms=30000&slide=id.ge654a07513_0_9',
  COACH_HANDBOOK:
    'https://docs.google.com/document/u/1/d/e/2PACX-1vTzJQ0Fv3mnCBx3-t6kQDk-9uXwVpTaaM1fZvRfQH3zQ85n5nKl_VmlydTuJu0Pa6-yNgo3QH5yUkzO/pub',
  COACHING_HANDBOOK:
    'https://docs.google.com/document/u/1/d/e/2PACX-1vTlXPSz_enQQP6f7EComQf79oI3AZOOgeURlvV2RKaXXTg7A7m_IEMt3h8iK-8C8KF_Udz1ebgjzdu-/pub',
  AURA_LIVE_STANDARDS:
    'https://docs.google.com/document/u/1/d/e/2PACX-1vQpKMEwnJGh0YXrqQByWMu4_RW7y-uawWncsm45Gh3FPhaARpftObVX-ExVLK2NiHfQ4KIDuuDeuaMS/pub',
  EMERGENCY_PROTOCOL:
    'https://docs.google.com/document/d/1JixYlZ7WR-oqrobMLCnvuNE48652iiLfwolOYHirYm4/edit',
  PRIVACY_POLICY: 'https://www.aurahealth.io/privacy-policy',
  TERMS_OF_SERVICE: 'https://www.aurahealth.io/terms-of-service',
  COACHING_TERMS_OF_SERVICE: 'https://www.aurahealth.io/coaching-terms',
  PAYOUT_TERMS_AND_CONDITION: 'https://www.aurahealth.io/coaching-pricing',
  COACH_SUCCESS:
    'https://docs.google.com/document/u/1/d/e/2PACX-1vSssj-eaYnBWvpbKdNNTp4gOEi5a_tSSBUgTssSyUInPkFyV3D-MHdzJuRu9OJlJAhHwlg6hzQOHY2E/pub',
});

export default documentConstants;
