import React from 'react';
import classnames from 'classnames';
import Pulse from '../Pulse';
import styles from './styles.module.scss';

export default function AuraButton({
  title,
  onClick,
  icon,
  style,
  type,
  disabled,
  horizontalGradient,
  loading,
  withShadow,
  textStyle,
  className,
  subDescription,
}) {
  return (
    <button
      type={type || 'button'}
      className={classnames(styles.auraBtn, className, {
        [styles.btnShadow]: withShadow,
        [styles.horizontalGradient]: horizontalGradient,
      })}
      onClick={onClick}
      disabled={disabled || loading}
      style={style}>
      {loading ? (
        <Pulse size={24} color="white" />
      ) : (
        <>
          {icon && <span className={styles.btnIcon}>{icon}</span>}
          <div>
            <p style={textStyle}>{title}</p>
            <p className={styles.subDescription}>{subDescription}</p>
          </div>
        </>
      )}
    </button>
  );
}
