import React, { useState } from 'react';
import classnames from 'classnames';
import { Trans } from 'react-i18next';

import GoogleLoginBtn from './GoogleLoginBtn';
import FacebookLoginBtn from './FacebookLoginBtn';
import EmailLoginForm from './EmailLoginForm';

import styles from './styles.module.scss';
import documentConstants from '../../utils/constants/documents';
import useTranslations from '../../hooks/translations';

export default function LoginCard(props) {
  const {
    header,
    onSubmit,
    disableSocial,
    hideCard,
    buttonText,
    onClose,
    isSignup,
    style,
  } = props;

  const { t } = useTranslations();
  const [isSignupActive, setSignupActive] = useState(isSignup);

  return (
    <div
      className={classnames('w-100', styles.loginCard, { card: !hideCard })}
      style={style}>
      {onClose && (
        <img
          alt="Close"
          src="/static/images/icons/grayX.png"
          className={styles.closeIcon}
          onClick={onClose}
        />
      )}

      <div
        className={classnames(
          'col align-center justify-center',
          styles.header
        )}>
        {header && <h2 className={styles.title}>{header}</h2>}

        {!disableSocial && (
          <>
            <GoogleLoginBtn style={{ marginBottom: 12 }} />
            <FacebookLoginBtn />

            <span className={styles.loginDivider}>
              {t('onboarding_login_or')}
            </span>
          </>
        )}

        <EmailLoginForm
          inputContainerStyle={{ marginBottom: 8 }}
          inputStyle={{
            backgroundColor: '#EEF2F3',
            borderColor: '#EEF2F3',
          }}
          onSubmit={onSubmit}
          btnText={
            buttonText ||
            (isSignupActive
              ? t('onboarding_button_sign_up')
              : t('onboarding_login_sign_in'))
          }
          isSignUp={isSignup && isSignupActive}
          showForgotPassword
        />

        {isSignup && (
          <p
            className={styles.toggleText}
            onClick={() => {
              setSignupActive((curr) => !curr);
            }}>
            {isSignupActive
              ? t('onboarding_already_have_account')
              : t('onboarding_dont_have_account')}
            <span>
              {isSignupActive
                ? t('onboarding_sign_up_login')
                : t('onboarding_button_sign_up')}
            </span>
          </p>
        )}

        {isSignupActive && (
          <p className={styles.policyText}>
            <Trans
              i18nKey="onboarding_sign_up_agree_terms"
              components={[
                <a
                  key="link-privacy"
                  href={documentConstants.PRIVACY_POLICY}
                  target="_blank"
                  rel="noopener noreferrer"></a>,
                <a
                  key="link-terms"
                  href={documentConstants.TERMS_OF_SERVICE}
                  target="_blank"
                  rel="noopener noreferrer"></a>,
              ]}
            />
          </p>
        )}
      </div>
    </div>
  );
}
