import React from 'react';
import classnames from 'classnames';
import { FaFacebookF } from 'react-icons/fa';

import useToastMessage from '../../../hooks/toastMessage';
// import { signInWithRedirect } from '../../../services/FirebaseAuth';
import Auth from '../../../services/FirebaseAuth';
import LocalStorage from '../../../services/LocalStorage';

import styles from './styles.module.scss';
import useTranslations from '../../../hooks/translations';

export default function FacebookLoginBtn({
  btnText,
  style,
  iconStyle,
  textStyle,
  showErrorToasts = true,
}) {
  const { t } = useTranslations();
  const Toast = useToastMessage();

  async function onClickBtn() {
    Auth.FacebookProvider.addScope('public_profile, email');
    try {
      const result = await Auth.signInWithPopup(
        Auth.instance,
        Auth.FacebookProvider,
        Auth.browserPopupRedirectResolver
      );
      if (result.credential) {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const token = result.credential.accessToken;
        LocalStorage.setItem('facebook_token', token);
      }
    } catch (error) {
      if (showErrorToasts) {
        Toast.showError(error.message);
      }
    }
  }

  return (
    <button
      className={classnames('clickable', styles.facebookBtn)}
      style={style}
      onClick={onClickBtn}>
      <span className={styles.fbIcon}>
        <FaFacebookF size="18px" color="#fff" style={iconStyle} />
      </span>

      <span className={styles.facebookBtnText} style={{ ...textStyle }}>
        {btnText || t('onboarding_login_facebook_login')}
      </span>
    </button>
  );
}
