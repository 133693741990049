import React from 'react';
import classnames from 'classnames';

import Auth from '../../../services/FirebaseAuth';
import LocalStorage from '../../../services/LocalStorage';
import useToastMessage from '../../../hooks/toastMessage';

import styles from './styles.module.scss';
import useTranslations from '../../../hooks/translations';

export default function GoogleLoginBtn({
  btnText,
  style,
  iconStyle,
  textStyle,
  showErrorToasts = true,
}) {
  const { t } = useTranslations();
  const Toast = useToastMessage();
  async function onClickBtn() {
    Auth.GoogleProvider.addScope(
      'https://www.googleapis.com/auth/userinfo.email'
    );
    try {
      const result = await Auth.signInWithPopup(
        Auth.instance,
        Auth.GoogleProvider,
        Auth.browserPopupRedirectResolver
      );
      if (result.credential) {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const token = result.credential.accessToken;
        LocalStorage.setItem('google_token', token);
      }
    } catch (error) {
      if (showErrorToasts) {
        Toast.showError(error.message);
      }
    }
  }

  return (
    <button
      className={classnames('clickable', styles.googleBtn)}
      style={style}
      onClick={onClickBtn}>
      <img
        className={styles.googleIcon}
        src="/static/images/icons/google.png"
        alt="Google icon"
        style={iconStyle}
      />

      <span className={styles.googleBtnText} style={{ ...textStyle }}>
        {btnText || t('onboarding_login_google_login')}
      </span>
    </button>
  );
}
