import { useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import useAuthUser from './authUser';
import {
  getUserPreferredLocale,
  getISOLocale,
  getAllLocales,
} from '../models/locale';
import { getCookie } from '../utils';
import Logger from '../services/Logger';
import Analytics from '../services/Analytics';

function useUserPreferredLocale({ allowMajorLocaleMatching = false } = {}) {
  const router = useRouter();
  const { user, isLoading, authLoading } = useAuthUser();
  const { locale: userLocale } = user || {};
  const localeList = useMemo(
    () => Object.keys(getAllLocales({ type: 'app' })),
    []
  );

  const { pathname, query, asPath, locale } = router;

  useEffect(() => {
    if (isLoading || authLoading) return;
    const currentLocale = getISOLocale(locale);

    let preferredLocale = userLocale;
    // Locale preference order - user profile, cookies or local storage, browser settings
    if (!userLocale) {
      const cookieLocale = getCookie('PREFERRED_LOCALE');
      Logger.debug('No user locale found, getting cookie locale', {
        cookieLocale,
      });
      if (cookieLocale) {
        preferredLocale = getISOLocale(cookieLocale);
      } else if (allowMajorLocaleMatching) {
        preferredLocale = getUserPreferredLocale().id;
      } else if (typeof window !== 'undefined' && window.navigator?.language) {
        Logger.debug('No cookie locale found, getting browser locale', {
          cookieLocale,
        });
        preferredLocale = window.navigator?.language;
      }
    }
    Analytics.setSuperProperties({ Locale: preferredLocale || currentLocale });

    if (!preferredLocale || currentLocale === preferredLocale) return;

    if (
      !localeList ||
      localeList.length === 0 ||
      !localeList.includes(preferredLocale)
    ) {
      return;
    }

    router.replace({ pathname, query }, asPath, { locale: preferredLocale });
  }, [
    asPath,
    authLoading,
    isLoading,
    localeList,
    locale,
    pathname,
    query,
    router,
    userLocale,
    allowMajorLocaleMatching,
  ]);
}

export default useUserPreferredLocale;
