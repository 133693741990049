import React from 'react';
import Pulse from '../Pulse';
import styles from './styles.module.scss';

const DEFAULT_LOADER_COLOR = '#03a9f4';
const DEFAULT_LOADER_SIZE = 100;

export default function Loader({ size, color, style, text, inline }) {
  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: inline ? 'auto' : '100vh',
        width: inline ? 'auto' : '100%',
        justifyContent: 'center',
        ...(style || {}),
      }}>
      <Pulse
        color={color || DEFAULT_LOADER_COLOR}
        size={size || DEFAULT_LOADER_SIZE}
      />

      {text && <h4 className={styles.text}>{text}</h4>}
    </div>
  );
}
