import { useEffect, useCallback, useRef } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { useDispatch } from 'react-redux';
import Router from 'next/router';

import Auth from '../services/FirebaseAuth';
import Logger from '../services/Logger';
import Analytics from '../services/Analytics';

import { setAuthLoading, getUserWithAuth, logout } from '../store/slices/auth';

import useToastMessage from './toastMessage';

import routeConstants from '../utils/constants/routes';
import { handleUpdateUser } from '../models/user';

const publicRoutes = [routeConstants.PAGE_LOGIN, routeConstants.PAGE_SIGNUP];

export default function useAuthListener(onAuthChange, { allowSignup } = {}) {
  const dispatch = useDispatch();
  const savedCallback = useRef();
  const Toast = useToastMessage();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = onAuthChange;
  }, [onAuthChange]);

  const onAuthStateChangedHandler = useCallback(
    async (authData) => {
      dispatch(setAuthLoading(true));
      Logger.debug(
        'auth change',
        authData
          ? {
              uid: authData.uid,
              email: authData.email,
              providerData: authData.providerData,
            }
          : null
      );
      const auth = {
        isLoggedIn: false,
        data: null,
        user: null,
      };
      if (authData) {
        auth.isLoggedIn = true;
        auth.data = authData;
        auth.userId = authData.uid;
        const user = await dispatch(getUserWithAuth(authData)).unwrap();
        if (user && !user.error) {
          auth.user = user;
          auth.userId = auth.user.id;
          // Update timezone for coach on user profile
          if (typeof Intl !== 'undefined') {
            const timezone =
              Intl?.DateTimeFormat().resolvedOptions().timeZone || null;
            if (timezone && user.timezone !== timezone) {
              handleUpdateUser({ timezone }, user.id);
            }
          }
          Analytics.signIn(auth.user);
        } else if (allowSignup) {
          auth.isNewUser = true;
        } else {
          // If only login and no user found for auth data, show an error message and sign out the user
          Toast.showError(
            'Could not find an existing user with the given email. Please sign up for Aura first.'
          );
          dispatch(logout());
          dispatch(setAuthLoading(false));
          return;
        }
        dispatch(setAuthLoading(false));
      } else if (Router.router) {
        if (!publicRoutes.includes(Router.pathname)) {
          dispatch(logout());
          Router.push({
            pathname: routeConstants.PAGE_LOGIN,
            query: {
              pathname: Router.pathname,
              asPath: Router.asPath,
            },
          });
          dispatch(setAuthLoading(false));
        } else {
          dispatch(setAuthLoading(false));
        }
      }
      if (typeof savedCallback.current === 'function') {
        savedCallback.current(auth);
      }
    },
    [dispatch, Toast, allowSignup]
  );
  useEffect(() => {
    Logger.debug('Setting up auth listener');
    try {
      const unsubscribe = onAuthStateChanged(
        Auth.instance,
        onAuthStateChangedHandler
      );
      return () => {
        Logger.debug('cleaning auth listener');
        dispatch(setAuthLoading(false));
        unsubscribe();
      };
    } catch (err) {
      Logger.error('Error setting up auth listener', err);
      return null;
    }
  }, [onAuthStateChangedHandler, dispatch]);
  return null;
}
