import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Router from 'next/router';
import NProgress from 'nprogress';
import LocaleSwitch from '../components/app/LocaleSwich';
import I18NFormatter from '../services/I18NFormatter';
import useTranslations from '../hooks/translations';
import DefaultHeadTags from '../components/app/DefaultHeadTags';
import { setAppLocale } from '../store/slices/app';
import Analytics from '../services/Analytics';
import useUserPreferredLocale from '../hooks/userPreferredLocale';

// NProgress
NProgress.configure({ showSpinner: false });

Router.events.on('routeChangeStart', () => {
  NProgress.start();
});

Router.events.on('routeChangeComplete', () => {
  NProgress.done();

  window.scrollTo(0, 0);
});

Router.events.on('routeChangeError', () => {
  NProgress.done();
});

export default function BaseLayout({
  children,
  hideBackground,
  hideLocaleSwitcher = true,
  allowMajorLocaleMatching = false,
}) {
  const dispatch = useDispatch();
  const { currentLocale } = useTranslations();
  useUserPreferredLocale({ allowMajorLocaleMatching });
  useEffect(() => {
    Analytics.init();
  }, []);

  useEffect(() => {
    I18NFormatter.init(currentLocale);
    dispatch(setAppLocale(currentLocale));
  }, [currentLocale]);

  return (
    <div style={{ position: 'relative' }}>
      {!hideLocaleSwitcher && (
        <div className="desktop-local-switch-position">
          <LocaleSwitch />
        </div>
      )}
      <DefaultHeadTags />

      {!hideBackground && <div id="app-background" />}

      {children}
    </div>
  );
}
