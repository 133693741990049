import { useState, useEffect } from 'react';
import Router from 'next/router';
import useAuthUser from '../../../hooks/authUser';
import { permissions, roles } from '../../../utils/constants/roles';
import routeConstants from '../../../utils/constants/routes';

export const checkAuthorizedForFeature = ({
  feature,
  user,
  contentOwnerId,
}) => {
  let grantedPermission = false;
  if (user) {
    const allowedFeatures = permissions[user.role];
    if (allowedFeatures && allowedFeatures.includes(feature)) {
      if (user.role === roles.CREATOR) {
        if (!contentOwnerId || user.id === contentOwnerId) {
          grantedPermission = true;
        }
      } else {
        grantedPermission = true;
      }
    }
  }
  return grantedPermission;
};

const RestrictedFeature = ({
  feature,
  contentOwnerId,
  shouldRedirect,
  children = null, // Supports both render props and component. Render props is called with value for isAuthorized
}) => {
  const { user } = useAuthUser();
  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    if (user) {
      const grantedPermission = checkAuthorizedForFeature({
        feature,
        user,
        contentOwnerId,
      });
      setIsAuthorized(grantedPermission);
      if (shouldRedirect && !grantedPermission) {
        Router.push(routeConstants.PAGE_RESTRICTED);
      }
    }
  }, [user, feature, shouldRedirect, contentOwnerId]);

  if (typeof children === 'function') {
    return children(isAuthorized);
  }
  return isAuthorized ? children : null;
};

export default RestrictedFeature;
